import $ from 'jquery';
import 'select2';
import './_components';

$(function () {
    let selects = $('select');

    if (selects.length > 0) {
        selects.each((i, el) => {
            $(el).select2();
        });
    }
});
